import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {PriceLabel} from '../../models/PriceLabel';
import {DropdownChangeEvent} from 'primeng/dropdown';

@Component({
  selector: 'app-label-list',
  templateUrl: './label-list.component.html',
  styleUrls: ['./label-list.component.css'],
})
export class LabelListComponent implements OnInit {
  labels: PriceLabel[] = [];
  filteredLabels: PriceLabel[] = [];
  scenarios: string[] = [];
  selectedScenario: string | undefined;

  constructor(private api: DataService) {}

  ngOnInit() {
    this.loadLabels();
  }

  loadLabels() {
    this.api.getLabels().subscribe(a => {
      this.labels = a;
      this.filteredLabels = this.labels;

      this.scenarios = Array.from(
        new Set(this.labels.map(label => label.scenario))
      );
    });
  }

  onSearchChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const searchValue = inputElement.value.toLowerCase();
    this.filteredLabels = this.labels.filter(
      product =>
        product.id.toLowerCase().includes(searchValue) ||
        product.productId.toLowerCase().includes(searchValue) ||
        product.scenario.toLowerCase().includes(searchValue)
    );
  }

  onScenarioChange(event: DropdownChangeEvent) {
    this.selectedScenario = event.value;
    if (this.selectedScenario) {
      this.filteredLabels = this.labels.filter(
        label => label.scenario === this.selectedScenario
      );
    } else {
      this.filteredLabels = this.labels;
    }
  }
}
