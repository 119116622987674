import { HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { CacheService } from '../services/cache.service';
import { inject, Injectable } from '@angular/core';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  private cacheService: CacheService = inject(CacheService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // if request is get artile we give back the cached article list to improve performance
    if (req.url.endsWith("articles") && req.method === "GET") {
      const cachedResponse = this.cacheService.get(req.urlWithParams);

      if (cachedResponse) {
        return of(cachedResponse);
      }
      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.cacheService.set(req.urlWithParams, event);
          }
        })
      );
    } else {
      return next.handle(req);
    }

  }
}