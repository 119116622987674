import {Component, inject, OnInit} from '@angular/core';
import {DropdownChangeEvent, DropdownModule} from 'primeng/dropdown';
import {AuthService} from '../../services/auth.service';
import {StoreService} from '../../services/store.service';
import {Store} from '../../models/Store';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-store-switch',
  standalone: true,
  imports: [DropdownModule, FormsModule, TranslateModule],
  templateUrl: './store-switch.component.html',
  styleUrl: './store-switch.component.css',
})
export class StoreSwitchComponent implements OnInit {
  auth = inject(AuthService);
  storeService = inject(StoreService);
  stores: Store[] = [];
  selectedStore: Store | undefined;

  constructor() {}

  ngOnInit() {
    this.loadStores();
  }

  loadStores() {
    if (this.auth.user?.isMaster) {
      this.storeService.getStores().subscribe(stores => {
        this.stores = stores;

        if (
          this.auth.user !== undefined &&
          this.auth.user.storeId !== undefined
        ) {
          this.selectedStore = this.stores.find(
            s => s.id === this.auth.user?.storeId
          );
        }
      });
    }
  }

  onStoreChange(event: DropdownChangeEvent) {
    const selStore = event.value as Store;
    this.selectedStore = selStore;
    sessionStorage.setItem('storeId', selStore.id);
    if (this.auth.user) {
      if (selStore.id !== this.auth.user.storeId) {
        this.auth.user.storeId = selStore.id;
        window.location.reload();
      }
    }
  }
}
