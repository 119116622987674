import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Amplify } from 'aws-amplify';
import { environment } from './environments/environment';
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: environment.cognito.userPoolId,
      userPoolClientId: environment.cognito.clientId
    }
  }
})

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
