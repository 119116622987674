import { Component, OnInit } from '@angular/core';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

interface Language {
  name: string;
  code: string;
}

@Component({
  selector: 'app-language-switcher',
  standalone: true,
  imports: [DropdownModule, FormsModule],
  templateUrl: './language-switcher.component.html',
  styleUrl: './language-switcher.component.css',
})
export class LanguageSwitcherComponent implements OnInit {
  languages: Language[]; // Add your supported languages here
  selectedLang!: Language;

  constructor(private translate: TranslateService) {
    this.languages = [
      { code: 'de', name: 'Deutsch' },
      { code: 'nl', name: 'Nederlands' },
    ];
  }

  ngOnInit() {
    const savedLang = localStorage.getItem('selectedLanguage') || 'en';
    this.selectedLang =
      this.languages.find(lang => lang.code === savedLang) || this.languages[0];
    this.translate.use(this.selectedLang.code);
  }

  changeLanguage(event: DropdownChangeEvent) {
    const langCode = event.value.code;
    this.translate.use(langCode);
    this.translate.reloadLang(langCode);
    localStorage.setItem('selectedLanguage', langCode);
  }
}
