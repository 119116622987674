import { inject, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ReplaySubject } from 'rxjs';
import {
  fetchAuthSession,
  fetchUserAttributes,
  signIn,
  AuthError,
  resetPassword,
  confirmResetPassword,
  confirmSignIn,
  signOut,
} from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';

export interface LouisUser {
  id: string | undefined;
  email: string | undefined;
  storeId: string | undefined;
  isMaster: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private msgService = inject(MessageService);
  private _user: LouisUser | undefined;
  private authSignal: boolean | undefined;
  public authenticationSignalAsync = new ReplaySubject<boolean>();

  /**
  * Retrieves the currently authenticated user's attributes if they are not already loaded.
  * @returns {LouisUser | undefined} The user object or undefined if not authenticated.
  */
  public get user(): LouisUser | undefined {
    if (!this._user) {
      // check if user is already loaded if not reset auth signal to reload components with loaded user
      this.checkAuthSessionInteral(false);
    }
    return this._user;
  }


  /**
  * Getter for the authentication status signal.
  * @returns {boolean | undefined} True if authenticated, false or undefined otherwise.
  */
  public get authenticationSignal(): boolean | undefined {
    return this.authSignal;
  }

  /**
   * Setter for authentication signal. Updates the `authenticationSignalAsync` observable with the new value.
   * @param {boolean} value - The new authentication status.
   */
  private set authenticationSignal(value: boolean) {
    this.authSignal = value;
    this.authenticationSignalAsync.next(value);
  }

  /**
    * Checks the current authentication session. If authenticated, loads user data.
    * Does not affect the authentication signal.
    */
  public async checkAuthSession() {
    await this.checkAuthSessionInteral(false);
  }

  /**
   * Internal method to check the authentication session status.
   * If authenticated, loads the user data and updates the authentication signal based on the omitSignal flag.
   * @param {boolean} [omitSignal=true] - Determines whether to omit updating the authentication signal.
   * @returns {Promise} Resolves to the current authentication session.
   * @private
   */
  public async checkAuthSessionInteral(omitSignal: boolean = true) {
    const session = await fetchAuthSession();
    const isAuth = session.userSub ? true : false;
    console.debug('isAuth: ', isAuth)
    if (isAuth) {
      await this.loadUser();
    }
    if (!omitSignal) {
      this.authenticationSignal = session.userSub ? true : false;
    }

    return session;
  }


  /**
   * Asynchronously loads the user's attributes and stores them in the private _user variable.
   * Fetches user details such as email, id, storeId, and whether they are a master user.
   * @private
   */
  private async loadUser() {
    if (this.authSignal) {
      const attributes = await fetchUserAttributes();
      this._user = {
        email: attributes['email'],
        id: attributes['sub'],
        storeId: this.getStoreId(attributes),
        isMaster: attributes['custom:isMaster'] === 'true',
      };
    }
  }

  /**
   * Attempts to sign in the user with the provided username and password.
   * Supports 'stay' option for keeping the session persistent using cookies.
   * Handles various sign-in steps and errors, returning appropriate status strings.
   * @param {string} username - The username of the user.
   * @param {string} password - The password of the user.
   * @param {boolean} [stay=false] - Whether to persist the session with cookies.
   * @returns {Promise<string>} The result of the sign-in process ('done', 'challenge', 'wrong', or 'unknown').
   */
  public async signIn(username: string, password: string, stay?: boolean) {
    if (stay) {
      cognitoUserPoolsTokenProvider.setKeyValueStorage(
        new CookieStorage({ sameSite: 'none' })
      );
    } else {
      cognitoUserPoolsTokenProvider.setKeyValueStorage(
        new CookieStorage({
          expires: NaN,
          sameSite: 'strict',
        })
      );
    }

    const signInResponse = await signIn({
      username,
      password,
      options: {
        authFlowType: 'USER_SRP_AUTH',
      },
    }).catch(e => {
      if (e instanceof AuthError) {
        return e;
      }
      console.error(e);
      throw e;
    });

    if (signInResponse instanceof AuthError) {
      console.error(signInResponse);
      return 'wrong';
    }

    if (signInResponse.isSignedIn) {
      await this.checkAuthSession();
      return 'done';
    } else {
      switch (signInResponse.nextStep.signInStep) {
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
          return 'challenge';
        default:
          console.warn(
            'Unknown signing in step ',
            signInResponse.nextStep.signInStep
          );
          break;
      }
    }
    return 'unknown';
  }

  /**
   * Initiates the password reset process for a user by sending a reset request.
   * @param {string} username - The username for which to reset the password.
   * @returns {Promise<void>}
   */
  public async resetPassword(username: string) {
    await resetPassword({
      username,
    });
  }

  /**
     * Confirms the password reset process by providing the new password and the confirmation code.
     * After successful confirmation, the user is signed in automatically.
     * @param {string} username - The username for which to confirm the password reset.
     * @param {string} newPassword - The new password to set.
     * @param {string} confirmationCode - The confirmation code received for password reset.
     * @returns {Promise<void>}
     */
  public async confirmResetPassword(
    username: string,
    newPassword: string,
    confirmationCode: string
  ) {
    try {
      await confirmResetPassword({
        username,
        newPassword,
        confirmationCode,
      });
      this.msgService.add({
        summary: 'Gespeichert!',
        detail: 'Passwort wurde erfolgreich gespeichert',
        severity: 'success',
      });
      await this.signIn(username, newPassword);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * Completes the challenge when the user is required to set a new password during sign-in.
   * @param {string} newPassword - The new password to set.
   * @returns {Promise<void>}
   */
  public async setNewRequiredPassword(newPassword: string) {
    try {
      const result = await confirmSignIn({
        challengeResponse: newPassword,
      });
      this.msgService.add({
        summary: 'Gespeichert!',
        detail: 'Passwort wurde erfolgreich gespeichert',
        severity: 'success',
      });
      if (result.isSignedIn) await this.checkAuthSession();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * Signs the user out, clears the session storage, and updates the authentication signal.
   * @returns {Promise<void>}
   */
  public async signOut() {
    await signOut();
    sessionStorage.clear();
    this.authenticationSignal = false;
  }

  /**
  * Retrieves the storeId from user attributes. If the user is a master user, retrieves the storeId from session storage.
  * @param {any} attributes - The user's attributes from the Cognito User
  * @returns {string} The storeId associated with the user.
  */
  private getStoreId(attributes: any): string {
    if (attributes['custom:isMaster'] === 'true') {
      const sessionStore = sessionStorage.getItem('storeId');
      if (sessionStore) {
        return sessionStore;
      }
    }

    return attributes['custom:storeId'];
  }
}
