<div class="login-container">
  <div style="text-align: center" class="escaper" routerLink="/">
    <h1 style="margin: 0; font-size: 32pt">Louis ESL Portal</h1>
    <img src="assets/images/logo_louis.svg" height="75" />
  </div>
  <p-divider layout="vertical"></p-divider>
  <h2>{{ pwdTitle }}</h2>
</div>
<br />
<p-steps [model]="steps" [(activeIndex)]="stepIndex"></p-steps>
<br />
<br />
<div style="text-align: center; width: 100%">
    <div>
      <form
        [formGroup]="newPasswordForm"
        (ngSubmit)="setNewPassword()"
        style="display: flex; gap: 5px; flex-wrap: wrap; justify-content: center"
        >
        <p-password
          formControlName="newPassword"
          [placeholder]="'PlaceholderNewPassword' | translate"
          [strongRegex]="passwordRegex"
          [promptLabel]="'PromptLabel' | translate"
          [weakLabel]="'WeakLabel' | translate"
          [strongLabel]="'StrongLabel' | translate"
        ></p-password>
        <button
          pButton
          type="submit"
          [disabled]="newPasswordForm.invalid || ongoingRequest"
          [text]="'ButtonSetPassword' | translate"
        >
        </button>
      </form>
    </div>
</div>
