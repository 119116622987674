import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  items: MenuItem[] | undefined;

  activeItem: MenuItem | undefined;

  constructor(
    private auth: AuthService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.translate
      .stream([
        'NavBarUploadArticles',
        'NavbarLinks',
        'NavBarArticles',
        'NavBarLabels',
        'NavBarLogout',
      ])
      .subscribe(res => {
        this.auth.authenticationSignalAsync.subscribe(state => {
          if (state) {
            const baseItems = [
              {
                label: res['NavbarLinks'],
                icon: 'pi pi-fw pi-link',
                routerLink: 'links',
              },
              {
                label: res['NavBarArticles'],
                icon: 'pi pi-fw pi-list',
                routerLink: 'articles',
              },
              {
                label: res['NavBarLabels'],
                icon: 'pi pi-fw pi-tags',
                routerLink: 'labels',
              },
              {
                label: res['NavBarLogout'],
                icon: 'pi pi-sign-out',
                command: () => this.auth.signOut(),
                style: { 'margin-left': 'auto' },
              },
            ];

            const masterOnlyItem = {
              label: res['NavBarUploadArticles'],
              icon: 'pi pi-fw pi-file-import',
              routerLink: 'products',
            };
            console.debug('User in Navbar: ', this.auth.user)
            this.items = this.auth.user?.isMaster
              ? [masterOnlyItem, ...baseItems]
              : baseItems;
          }
        });

        this.activeItem = this.items ? this.items[0] : undefined;
      });
  }
}
