<p-confirmDialog></p-confirmDialog>
<div class="steps-container">
  <p-steps [model]="matchingSteps" [(activeIndex)]="stepIndex"> </p-steps>
  <p-divider type="solid" [layout]="'vertical'"></p-divider>
  <div class="steps-content-container">
    <div class="form-container">
      @if (stepIndex >= 0) {
      <div pFocusTrap class="content-element">
        <p-dropdown
          id="product"
          dataKey="id"
          [placeholder]="'productDropdownPlaceholder' | translate"
          filterBy="id,description,barcode"
          optionLabel="descMain"
          [options]="products"
          [(ngModel)]="selectedProduct"
          [filter]="true"
          [showClear]="true"
          [style]="{width: '100%'}"
          [disabled]="stepIndex > 0"
          [virtualScroll]="true"
          [virtualScrollItemSize]="38"
        >
          <ng-template let-article pTemplate="item">
            {{ article.descMain }} | {{ article.id }}
            {{ article.barcode ? ' | ' + article.barcode : '' }}
          </ng-template>
        </p-dropdown>
        <p-card>
          @if (selectedProduct === undefined) {
          <div
            style="display: flex; flex-direction: column; text-align: center"
          >
            <p style="font-size: 1rem">
              {{ 'NoArticleScannedLabel' | translate }}
            </p>
            <p style="font-size: 1rem">
              {{ 'ScanArticleNowLabel' | translate }}
            </p>
          </div>
          } @else {
          <p>{{ 'articleIdLabel' | translate }} {{ selectedProduct!.id }}</p>
          <p>
            {{ 'articleDescriptionLabel' | translate }}
            {{ selectedProduct!.description }}
          </p>
          <p>
            {{ 'articleBarcodeLabel' | translate }}
            {{ selectedProduct!.barcode }}
          </p>
          <p>
            {{ 'articleListPriceLabel' | translate }}
            {{ selectedProduct!.listPrice }}€
          </p>
          <p>
            {{ 'articleOfferPriceLabel' | translate }}
            {{ selectedProduct!.offerPrice }}€
          </p>
          }
        </p-card>
      </div>
      } @if (stepIndex >= 1) {
      <div class="content-element">
        <p-chips
          [(ngModel)]="labelsIds"
          [style]="{width: '100%'}"
          [separator]="separatorExp"
          [disabled]="stepIndex > 1"
          pAutoFocus
          [autofocus]="true"
          [addOnBlur]="true"
          [addOnTab]="true"
          [allowDuplicate]="false"
          placeholder="Labels"
        ></p-chips>
      </div>
      } @if (stepIndex >= 2) {
      <div class="content-element">
        <p-selectButton
          id="selectedScenario"
          [options]="scenarioOptions"
          [(ngModel)]="selectedScenario"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>
      </div>
      }
    </div>
    <div class="nav-button-container">
      <p-button
        [label]="'prevStepLabel' | translate"
        icon="pi pi-chevron-left"
        (click)="prevStep()"
        [disabled]="stepIndex === 0"
      ></p-button>
      @if (stepIndex !== matchingSteps.length - 1) {
      <p-button
        [label]="'nextStepLabel' | translate"
        icon="pi pi-chevron-right"
        iconPos="right"
        (click)="nextStep()"
      ></p-button>
      } @else {
      <p-button
        [label]="'saveFormLabel' | translate"
        icon="pi pi-chevron-right"
        iconPos="right"
        (click)="saveForm()"
        severity="success"
        [disabled]="!canSaveForm"
      ></p-button>
      }
    </div>
  </div>
  <p-divider layout="vertical" type="solid"></p-divider>
  <div class="matches-table">
    <p-table [scrollable]="true" scrollHeight="30rem" [value]="labelsToUpload">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'TableLabelsHeader' | translate }}</th>
          <th>{{ 'TableHeaderArticleId' | translate }}</th>
          <th>{{ 'TableHeaderScenario' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-label>
        <tr>
          <td>{{ label.id }}</td>
          <td>{{ label.productId }}</td>
          <td>{{ label.scenario }}</td>
        </tr>
      </ng-template>
    </p-table>
    <div class="flex flew-row gap-3 justify-content-between">
      <p-button
        [label]="'resetLabel' | translate"
        icon="pi pi-replay"
        styleClass="p-button-danger"
        (click)="cancel()"
      ></p-button>
      <p-button
        [label]="'uploadLabel' | translate"
        icon="pi pi-check-circle"
        styleClass="p-button-success"
        [disabled]="!canSave"
        (click)="uploadLabels()"
      ></p-button>
    </div>
  </div>
</div>
