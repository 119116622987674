<div class="space-wrapper">
  <div class="login-container">
    <div style="text-align: center">
      <div class="logo-container">
        <div class="image-wrapper" style="text-align: center">
          <img src="assets/images/logo_louis.svg" height="75" />
        </div>
        <h1 style="margin: 0; font-size: 32pt">ESL-Portal</h1>
      </div>
    </div>
    <p-divider class="hide-portrait" layout="vertical"></p-divider>
    <p-divider class="show-portrait"></p-divider>
    <div class="interactionContainer">
      <form [formGroup]="loginForm" (ngSubmit)="performLogin()">
        <div class="p-input-icon-left" style="width: 100%; margin-bottom: 1rem">
          <i class="pi pi-user"></i>
          <input
            type="text"
            pInputText
            formControlName="username"
            [placeholder]="'PlaceholderUsername' | translate"
            class="usrpwd"
          />
        </div>
        <div class="p-input-icon-left" style="width: 100%; margin-bottom: 1rem">
          <i class="pi pi-key"></i>
          <input
            class="usrpwd"
            style="flex-grow: 1"
            type="password"
            formControlName="password"
            [placeholder]="'PlaceholderPassword' | translate"
            pPassword
            [feedback]="false"
          />
        </div>
        <p-checkbox
          formControlName="stayIn"
          [label]="'StayIn' | translate"
          [binary]="true"
          inputId="binary"
        ></p-checkbox>
        <div
          style="
            display: flex;
            justify-content: space-between;
            gap: 5px;
            margin-top: 1rem;
          "
        >
          <button
            pButton
            [disabled]="loginForm.invalid || ongoingRequest"
            style="flex-grow: 1"
            type="submit"
            [label]="'LoginLabel' | translate"
          ></button>
        </div>
      </form>
    </div>
  </div>
</div>
