import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {ConfirmationService} from 'primeng/api';
import {DatePipe} from '@angular/common';
import {ILouisArticleDto} from '../../models/Product';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.css'],
  providers: [ConfirmationService],
})
export class ArticleListComponent implements OnInit {
  articles: ILouisArticleDto[] = [];
  filteredArticles: ILouisArticleDto[] = [];
  loading: boolean = false;

  constructor(
    private api: DataService,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.loadArticles();
  }

  loadArticles() {
    this.loading = true;
    this.api.getArticles().subscribe(a => {
      this.articles = a;
      this.filteredArticles = this.articles;
      this.loading = false;
    });
  }

  onSearchChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const searchValue = inputElement.value.toLowerCase();
    this.filteredArticles = this.articles.filter(
      product =>
        product.id.toLowerCase().includes(searchValue) ||
        product.description.toLowerCase().includes(searchValue) ||
        product.barcode?.toLowerCase().includes(searchValue) ||
        product.articleGroup.toLowerCase().includes(searchValue)
    );
  }

  delete(articleId: string, event: Event) {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Soll diese Verknüpfung wirklich gelöscht werden?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // confirm action
        this.api.deleteArticle(articleId).subscribe({
          next: () => this.loadArticles(),
          error: err => {
            console.error(err);
          },
        });
      },
      reject: () => {
        //reject action
      },
    });
  }

  getFormattedDate(timestamp: number) {
    const date = new Date(timestamp * 1000);
    return this.datePipe.transform(date, 'dd.MM.yy - HH:mm');
  }
}
