import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { PriceLabel } from '../models/PriceLabel';
import { ILouisArticleDto } from '../models/Product';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private readonly ENDPOINT: string = environment.apiEndpoint;

  constructor(private http: HttpClient) { }

  private get storeId(): string {
    return sessionStorage.getItem('storeId') ?? ''
  }

  /**
   * Retrieves a list of price labels from the api.
   *
   * @returns An Observable that emits an array of PriceLabel objects.
   * 
   * This method sends a GET request to fetch label data from the api. The response is wrapped
   * in an Observable, which resolves to an array of PriceLabel objects.
   */
  public getLabels(): Observable<PriceLabel[]> {
    const request = new HttpRequest('GET', `${this.ENDPOINT}/stores/${this.storeId}/labels`, {
      responseType: 'json',
    });

    return new Observable<PriceLabel[]>(subscriber => {
      lastValueFrom(
        this.http.request(request) as Observable<HttpResponse<PriceLabel[]>>
      )
        .then(resp => {
          subscriber.next(resp.body ?? []);
        })
        .catch(err => console.error(err));
    });
  }

  /**
   * Saves an array of price labels to the api.
   *
   * @param labels - An array of PriceLabel objects to save.
   * @returns An Observable that emits an array of saved PriceLabel objects.
   * 
   * This method sends a POST request with an array of labels to the api for saving.
   * The response is wrapped in an Observable, and errors are handled by logging them to the console.
   */
  public saveLabel(labels: PriceLabel[]) {
    const request = new HttpRequest<PriceLabel[]>(
      'POST',
      `${this.ENDPOINT}/stores/${this.storeId}/labels`,
      labels
    );

    return new Observable<PriceLabel[]>(subscriber => {
      lastValueFrom(
        this.http.request(request) as Observable<HttpResponse<PriceLabel[]>>
      )
        .then(resp => {
          subscriber.next(resp.body ?? []);
        })
        .catch(err => console.error(err));
    })
  };

  /**
   * Deletes a price label from the api by label ID.
   *
   * @param labelId - The ID of the label to delete.
   * @returns An Observable that completes when the label is deleted.
   * 
   * This method sends a DELETE request to the api to remove a label with the specified ID.
   * Once completed, the observable emits a void signal. Errors are logged to the console.
   */
  public deleteLabel(labelId: string) {
    const request = new HttpRequest(
      'DELETE',
      `${this.ENDPOINT}/stores/${this.storeId}/labels/${labelId}`
    );

    return new Observable<void>(subscriber => {
      lastValueFrom(
        this.http.request(request) as Observable<HttpResponse<void>>
      )
        .then(resp => {
          subscriber.next();
        })
        .catch(err => console.error(err));
    });
  }

  /**
   * Fetches a list of all articles from the api.
   *
   * @returns An Observable that emits an array of ILouisArticleDto objects.
   * 
   * This method sends a GET request to retrieve a list of articles. The result is wrapped in an
   * Observable that emits an array of article data. Errors are logged and sent back to the observable stream.
   */
  public getArticles() {
    const request = new HttpRequest('GET', `${this.ENDPOINT}/articles`);

    return new Observable<ILouisArticleDto[]>(subscriber => {

      lastValueFrom(
        this.http.request(request) as Observable<
          HttpResponse<ILouisArticleDto[]>
        >
      )

        .then(resp => {
          subscriber.next(resp.body ?? []);
        })
        .catch(err => {
          console.error(err);
          subscriber.error(err);
        });
    })
  }

  /**
     * Fetches a list of articles based on provided article IDs.
     *
     * @param articleIds - An array of article IDs to search for.
     * @returns An Observable that emits an array of ILouisArticleDto objects.
     * 
     * This method sends a POST request with an array of article IDs to the api. The response
     * contains article data that matches the provided IDs. Duplicate IDs are removed before the request.
     */
  public getArticlesForLabels(articleIds: string[]) {
    const request = new HttpRequest(
      'GET',
      `${this.ENDPOINT}/articles`,
      { articleIds: [...new Set(articleIds)]}
    );

    return new Observable<ILouisArticleDto[]>(subscriber => {
      lastValueFrom(
        this.http.request(request) as Observable<HttpResponse<ILouisArticleDto[]>>
      )
        .then(resp => {
          subscriber.next(resp.body ?? []);
        })
        .catch(err => {
          console.error(err);
          subscriber.error(err);
        });
    })
  }

  /**
     * Deletes an article by its ID from the api.
     *
     * @param articleId - The ID of the article to delete.
     * @returns An Observable that completes when the article is deleted.
     * 
     * This method sends a DELETE request to remove the specified article.
     */
  public deleteArticle(articleId: string) {
    return this.http.delete(`${this.ENDPOINT}/articles?articleId=${articleId}`);
  }

  /**
   * Uploads a file to the server.
   *
   * @param body - An object containing the file's name and content.
   * @returns An Observable that completes when the upload is finished.
   * 
   * This method sends a POST request with the file data to the /articles api endpoint.
   * The file content is expected to be in the format of a string.
   */
  public uploadFile(
    body: { fileName: string; fileContent: string }
  ) {
    const request = new HttpRequest('POST', `${this.ENDPOINT}/articles`, body);

    return new Observable<any>(subscriber => {
      lastValueFrom(
        this.http.request(request) as Observable<HttpResponse<any>>
      )
        .then(resp => {
          subscriber.next();
        })
        .catch(err => console.error(err));
    }
    );
  }
}
