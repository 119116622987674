import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  private subscription: Subscription | undefined;

  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    stayIn: new FormControl(false),
  });

  ongoingRequest = false;

  private retUrl = '/';

  constructor(
    private api: AuthService,
    private apiService: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.retUrl = params.get('retUrl') ?? '/';
      if (params.get('challengeSuccess')) {
        this.messageService.add({
          summary: 'Gespeichert!',
          detail: 'Passwort wurde erfolgreich gespeichert',
          severity: 'success',
        });
      }
    });
    this.subscription = this.api.authenticationSignalAsync.subscribe(sig => {
      if (sig) this.router.navigate([this.retUrl]);
    });
  }

  performLogin() {
    this.ongoingRequest = true;
    this.api
      .signIn(
        this.loginForm.controls.username.value ?? '',
        this.loginForm.controls.password.value ?? '',
        this.loginForm.controls.stayIn.value ?? false
      )
      .then(res => {
        this.ongoingRequest = false;
        switch (res) {
          case 'challenge':
            this.router.navigate(['/challenge']);
            break;
          case 'wrong':
            this.loginForm.reset({
              username: this.loginForm.controls.username.value,
              stayIn: this.loginForm.controls.stayIn.value,
            });
            this.messageService.add({
              summary: 'Versuch es bitte erneut',
              detail: 'Ungültiger Nutzername oder Kennwort',
              severity: 'error',
            });
            break;
          case 'done':
            this.apiService.getArticles().subscribe();
            break;
        }
      });
  }
}
