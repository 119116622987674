import { Component, inject, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './services/auth.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [CookieService, MessageService],
})
export class AppComponent implements OnInit {
  title = 'louis-portal';
  showMenu = false;

  public browserRefresh = false;
  private authService = inject(AuthService);

  constructor(
    private cookie: CookieService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.authService.checkAuthSession();
    this.authService.authenticationSignalAsync.subscribe(state => {
      this.showMenu = state;
      if (!state) {
        const route = router.routerState.snapshot.root;
        const oldParams = { ...route.queryParams };
        oldParams['retUrl'] = route.url;
        router.navigate(['login'], { queryParams: oldParams });
      }
    });
  }

  ngOnInit(): void {
    const savedLang =
      localStorage.getItem('selectedLanguage') || navigator.language;
    this.translate.setDefaultLang('de');
    this.translate.use(savedLang);
  }
}
