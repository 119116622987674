import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable, of, switchMap, tap } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  private authService: AuthService = inject(AuthService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.authService.checkAuthSessionInteral()).pipe(
      switchMap(session => {

        let request = req.clone({
          setHeaders: {
            Authorization: session.tokens?.idToken?.toString() ?? ''
          }
        });

        // storeId is not needed if the request is on the endpoint '/stores' or on a subressource
        if (!req.url.includes('/stores')) {
          request = request.clone({
            setParams: {
              storeId: this.authService.user?.storeId ?? ''
            }
          });
        }
        return next.handle(request);
      })
    );
  }

}