<div class="main flex flex-column gap-3">
  <div class="filter-container">
  <span class="p-input-icon-left mr-auto" style="width: 100%; margin: 0.5rem">
    <i class="pi pi-search"></i>
    <input
      type="text"
      pInputText
      (input)="onSearchChange($event)"
      [placeholder]="'searchLabel' | translate"
      style="width: 100%"
    />
  </span>
  <p-dropdown
    [options]="scenarios"
    [(ngModel)]="selectedScenario"
    [showClear]="true"
    [placeholder]="'PlaceholderFilterScenario' | translate"
    (onChange)="onScenarioChange($event)"
    [ngStyle]="{'margin': '0.5rem'}"></p-dropdown>
  </div>
  <p-table
    [value]="filteredLabels"
    dataKey="id"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50]"
    [paginator]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="id">
          {{ 'TableHeaderLabelId' | translate }}
          <p-sortIcon field="id"></p-sortIcon>
        </th>
        <th pSortableColumn="productId">
          {{ 'TableHeaderArticleId' | translate }}
          <p-sortIcon field="productId"></p-sortIcon>
        </th>
        <th pSortableColumn="scenario">
          {{ 'TableHeaderScenario' | translate
          }}<p-sortIcon field="scenario"></p-sortIcon>
        </th>
        <th>{{ 'TableHeaderStoreId' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-article>
      <tr>
        <td>{{ article.id }}</td>
        <td>{{ article.productId }}</td>
        <td>{{ article.scenario }}</td>
        <td>{{ article.storeId.replace('dgcs_de.', '') }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
