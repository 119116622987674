import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private cache = new Map<string, { response: HttpResponse<any>, expiration: number }>()

  constructor() { }

  /**
    * Stores an HTTP response in the cache.
    * 
    * @param key - The unique key used to identify the cached response.
    * @param value - The actual HttpResponse object to be cached.
    * @param ttl - Time-to-live in milliseconds for the cache entry (default is 30 minutes).
    * 
    * The `ttl` (time-to-live) defines how long the response should remain in the cache
    * before it is considered expired and removed. If no TTL is provided, the default value
    * is set to 1800000 milliseconds (30 minutes).
    */
  set(key: string, value: HttpResponse<any>, ttl: number = 1800000) { //Default TTL = 30 min
    const expiration = Date.now() + ttl;
    this.cache.set(key, { response: value, expiration });
  }

  /**
   * Retrieves a cached HTTP response based on the provided key.
   * 
   * @param key - The unique key used to look up the cached response.
   * @returns - The cached HttpResponse if it exists and hasn't expired, otherwise null.
   * 
   * This method first checks if the response exists in the cache. If found, it then verifies
   * whether the cache entry is still valid by comparing the current time to the cached expiration time.
   * If the entry has expired, it will be removed from the cache, and `null` is returned.
   */
  get(key: string) {
    const cached = this.cache.get(key);
    if (!cached) {
      return null;
    }

    if (cached.expiration < Date.now()) {
      this.cache.delete(key);
      return null;
    }

    return cached.response;
  }

}
