<div class="main flex flex-column gap-3">
  <div>
    <p-button
      [label]="'addLinkLabel' | translate"
      icon="pi pi-plus"
      [pTooltip]="'addLinkTooltip' | translate"
      (onClick)="addLink()"
    ></p-button>
  </div>
  <span class="p-input-icon-left mr-auto" style="width: 100%">
    <i class="pi pi-search"></i>
    <input
      type="text"
      pInputText
      (input)="onSearchChange($event)"
      [placeholder]="'searchLabel' | translate"
      style="width: 100%"
    />
  </span>
  <p-confirmPopup></p-confirmPopup>
  <p-table
    #dtLabels
    [value]="filteredArticles"
    dataKey="id"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50]"
    [paginator]="true"
    [globalFilterFields]="['id, name']"
    [loading]="loading"

  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 5rem"></th>
        <th pSortableColumn="id">
          {{ 'TableHeaderArticleId' | translate }}
          <p-sortIcon field="id"></p-sortIcon>
        </th>
        <th pSortableColumn="name">
          {{ 'TableHeaderArticleName' | translate }}
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="updated">
          {{ 'TableHeaderDateChanged' | translate }}
         <p-sortIcon field="updated"></p-sortIcon>
        </th>
        <th>{{ 'TableHeaderLabelId' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-article let-expanded="expanded">
      <tr>
        <td>
          <button
            type="button"
            pButton
            pRipple
            [pRowToggler]="article"
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          ></button>
        </td>
        <td>{{ article.id }}</td>
        <td>{{ article.name }}</td>
        <td>{{ getFormattedDate(article) }}</td>
        <td>
          {{ article.labels.length }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-article>
      <tr>
        <td colspan="5">
          <div class="p-3">
            <p-table
              [value]="article.labels"
              [globalFilterFields]="['id, storeId']"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="id">
                    {{ 'TableHeaderLabelId' | translate }}
                    <p-sortIcon field="id"></p-sortIcon>
                  </th>
                  <th>{{ 'TableHeaderScenario' | translate }}</th>
                  <th>{{ 'TableHeaderStoreId' | translate }}</th>
                  <th>{{ 'TableHeaderAction' | translate }}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-label>
                <tr>
                  <td>{{ label.id }}</td>
                  <td>{{ label.scenario }}</td>
                  <td>{{ label.storeId.replace('dgcs_de.', '') }}</td>
                  <td>
                    <div class="card flex flex-wrap gap-3">
                      <p-button
                        pRipple
                        type="button"
                        (click)="delete(label.id, $event)"
                        icon="pi pi-trash"
                        styleClass="p-button-danger"
                        [style]="{
                          margin: '0 0.25rem'
                        }"
                      ></p-button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
