<div class="card flex-column justify-content-center">
  <div class="upload-group">
    <h3>{{ 'FormUploadArticles' | translate }}</h3>
    <p-fileUpload #articleForm name="articleData[]" [chooseLabel]="'ChooseLabel' | translate"
      [cancelLabel]="'CancelLabel' | translate" [uploadLabel]="'UploadLabel' | translate" [maxFileSize]="5242800"
      mode="advanced" accept=".csv" [customUpload]="true" (uploadHandler)="onFileUploadClicked($event, articleForm)"
      (onSelect)="onFileChange($event)" (onClear)="clearFiles()" (onRemove)="clearFiles()"
      [showUploadButton]="fileIsValid">
      <ng-template pTemplate="content">
        @if (missingColumns.length > 0) {
        <div>
          <p-messages severity="error" [enableService]="false">
            <ng-template pTemplate>
              <div class="flex flex-row">
                <div>
                  <i class="pi pi-info-circle" style="font-size: 2rem; padding: 0 1rem 0 0"></i>
                </div>
                <div class="flex flex-column flex-grow-1">
                  <span>{{ 'InfoInvalidFile' | translate }}</span>
                  <span>
                    {{ 'InfoInvalidFileColumns' | translate }}
                  </span>
                  <ul class="p-mt-2">
                    @for (col of missingColumns; track col) {
                    <li>{{ col }}</li>
                    }
                  </ul>
                </div>
              </div>
            </ng-template>
          </p-messages>
        </div>
        }
      </ng-template>
    </p-fileUpload>
  </div>
</div>