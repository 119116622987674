<div class="main flex flex-column gap-3">
  <span class="p-input-icon-left mr-auto" style="width: 100%">
    <i class="pi pi-search"></i>
    <input
      type="text"
      pInputText
      (input)="onSearchChange($event)"
      [placeholder]="'searchLabel' | translate"
      style="width: 100%"
    />
  </span>
  <p-confirmPopup></p-confirmPopup>
  <p-table
    [value]="filteredArticles"
    dataKey="id"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50, 100, 1000]"
    [paginator]="true"
    [loading]="loading"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="id">
          {{ 'TableHeaderArticleId' | translate }}
          <p-sortIcon field="id"></p-sortIcon>
        </th>
        <th pSortableColumn="description">
          {{ 'TableHeaderArticleName' | translate }}
          <p-sortIcon field="description"></p-sortIcon>
        </th>
        <th pSortableColumn="description">
          {{ 'TableHeaderArticleGroup' | translate }}
          <p-sortIcon field="articleGroup"></p-sortIcon>
        </th>
        <th style="text-align: right">{{ 'TablePriceHeader' | translate }}</th>
        <th style="text-align: right">
          {{ 'TableHeaderListPrice' | translate }}
        </th>
        <th pSortableColumn="updated" style="text-align: right">
          {{ 'TableHeaderDateChanged' | translate
          }}<p-sortIcon field="updated"></p-sortIcon>
        </th>
        <th>{{ 'TableHeaderAction' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-article>
      <tr>
        <td>{{ article.id }}</td>
        <td>{{ article.description }}</td>
        <td>{{ article.articleGroup }}</td>
        <td style="text-align: right">{{ article.offerPrice }} €</td>
        <td style="text-align: right">{{ article.listPrice }} €</td>
        <td style="text-align: right">
          {{ getFormattedDate(article.updated) }}
        </td>
        <td>
          <div class="card flex flex-wrap gap-3">
            <p-button
              pRipple
              type="button"
              (click)="delete(article.id, $event)"
              icon="pi pi-trash"
              styleClass="p-button-danger"
              [style]="{
                margin: '0 0.25rem'
              }"
            ></p-button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
