import { DataService } from 'src/app/services/data.service';
import { Component, inject } from '@angular/core';
import {
  FileSelectEvent,
  FileUpload,
  FileUploadHandlerEvent,
} from 'primeng/fileupload';
import { Base64 } from 'js-base64';
import { MessageService } from 'primeng/api';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent {
  private dataService: DataService = inject(DataService);
  private messageService: MessageService = inject(MessageService);
  protected fileIsValid: boolean = false;
  missingColumns: string[] = [];
  constructor(
    private papa: Papa
  ) { }

  onFileUploadClicked(event: FileUploadHandlerEvent, form: FileUpload) {
    const selectedFile = event.files[0];
    const fileReader = new FileReader();
    fileReader.onload = (e: ProgressEvent<FileReader>) => {
      this.onFileRead(e, selectedFile.name);
    };
    fileReader.readAsText(selectedFile);
    form.clear();
  }

  onFileChange(event: FileSelectEvent) {
    const file = event.files[0];
    this.papa.parse(file, {
      preview: 1, //Weil wir nur die Überschriften benötigen
      complete: result => {
        let isValid = true;
        if (!Array.isArray(result.data)) {
          isValid = false;
        }

        if (isValid) {
          const expectedColumns = [
            'Artikelnummer',
            'Artikelbezeichnung',
            'Artikelgruppe',
            'Gruppenbezeichnung',
            'Groesse',
            'UVP',
            'Gegenueberstellungs-Kennzeichen',
            'Aktueller Preis',
            'Finanzierung',
            'Grundpreis',
            'Barcode',
            'QR-Code',
          ];
          const headlines = result.data[0];
          isValid = expectedColumns.every(col => headlines.includes(col));
          this.missingColumns = expectedColumns.filter(
            col => !headlines.includes(col)
          );

          this.fileIsValid = isValid;
        }
        return isValid;
      },
    });
  }

  clearFiles() {
    this.fileIsValid = true;
    this.missingColumns = [];
  }

  onFileRead(e: ProgressEvent<FileReader>, fileName: string) {
    /* Making the assertion, that e.target.result is always a string
      because "readAsDataUrl()" will cause it to always be a string */
    const base64data = Base64.encode(e.target?.result as string);
    const body = {
      fileName: fileName,
      fileContent: base64data,
    };

    this.dataService.uploadFile(body).subscribe({
      next: response => {
        this.messageService.add({
          severity: 'success',
          summary: 'Upload erfolgreich!',
          detail: 'Die Datei wurde erfolgreich hochgeladen',
          life: 5000,
        });
        // console.info('File uploaded:', response);
      },
      error: err => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ein Fehler ist aufgetreten!',
          detail:
            'Beim Hochladen der Datei ist ein Fehler aufgetreten. Stellen Sie Sicher dass die Daten stimmen und versuchen Sie es erneut oder kontaktieren Sie uns!',
          life: 5000,
        });
        console.error('An error occured while uploading the File:', err);
      },
    });
  }


}
