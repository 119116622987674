import {Component, inject, OnInit} from '@angular/core';
import {LanguageSwitcherComponent} from '../language-switcher/language-switcher.component';
import {StoreSwitchComponent} from '../store-switch/store-switch.component';
import {AuthService} from '../../services/auth.service';
import {StoreService} from '../../services/store.service';

@Component({
  selector: 'app-top-bar',
  standalone: true,
  imports: [LanguageSwitcherComponent, StoreSwitchComponent],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.css',
})
export class TopBarComponent implements OnInit {
  isMaster = false;
  constructor(
    private auth: AuthService,
    private storeService: StoreService
  ) {
    this.auth.authenticationSignalAsync.subscribe(state => {
      if (state) {
        this.isMaster = this.auth.user?.isMaster ?? false;
      }
    });
  }

  ngOnInit() {
    if (this.auth.user?.isMaster) {
      this.isMaster = true;
    }
  }
}
