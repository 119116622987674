import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { lastValueFrom, Observable } from 'rxjs';
import { Store } from '../models/Store';


@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private readonly ENDPOINT: string = environment.apiEndpoint;

  constructor(private http: HttpClient) { }

  public getStores() {
    const request = new HttpRequest('GET', `${this.ENDPOINT}/stores`, {
      responseType: 'json',
    });

    return new Observable<Store[]>(subscriber => {

      lastValueFrom(
        this.http.request(request) as Observable<HttpResponse<Store[]>>
      )
        .then(resp => {
          subscriber.next(resp.body ?? []);
        })
        .catch(err => console.error(err));
    });
  };

}
