import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PriceLabel} from '../../models/PriceLabel';
import {DataService} from '../../services/data.service';
import {ILouisArticleDto, ProductView} from '../../models/Product';
import {ConfirmationService} from 'primeng/api';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css'],
  providers: [ConfirmationService],
})
export class LinksComponent implements OnInit {
  constructor(
    private router: Router,
    private api: DataService,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe
  ) {}

  articles: ProductView[] = [];
  filteredArticles: ProductView[] = [];
  loading: boolean = false;

  connections: PriceLabel[] = [];
  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.api.getLabels().subscribe(data => {
      this.connections = data;
      this.loadArticles(this.connections.map(c => c.productId));
    });
  }

  loadArticles(productIds: string[]) {
    this.api.getArticlesForLabels(productIds).subscribe(data => {
      this.articles = data
        .map(a => ({
          id: a.id,
          name: a.descMain,
          labels: this.connections.filter(c => c.productId === a.id),
          updated: a.updated,
        }))
        .filter(l => l.labels.length > 0);
      this.filteredArticles = this.articles;
      this.loading = false;
    });
  }

  addLink() {
    this.router.navigate(['add']);
  }

  delete(labelId: string, event: Event) {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Soll diese Verknüpfung wirklich gelöscht werden?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // confirm action
        this.api.deleteLabel(labelId).subscribe(data => {
          this.loadData();
        });
      },
      reject: () => {
        //reject action
      },
    });
  }

  onSearchChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const searchValue = inputElement.value.toLowerCase();
    this.filteredArticles = this.articles.filter(
      product =>
        product.id.toLowerCase().includes(searchValue) ||
        product.name.toLowerCase().includes(searchValue) ||
        product.labels.some(
          label =>
            label.id.toLowerCase().includes(searchValue) ||
            label.scenario.toLowerCase().includes(searchValue)
        )
    );
  }

  getFormattedDate(article: ILouisArticleDto) {
    const date = new Date(article.updated * 1000);
    return this.datePipe.transform(date, 'dd.MM.yy - HH:mm');
  }
}
