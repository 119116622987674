import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { authGuard } from './guards/auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { ProductsComponent } from './components/products/products.component';
import { LinksComponent } from './components/links/links.component';
import { TableModule } from 'primeng/table';
import { RippleModule } from 'primeng/ripple';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipsModule } from 'primeng/chips';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { StepsModule } from 'primeng/steps';
import { ChipModule } from 'primeng/chip';
import { AddMultipleMatchesComponent } from './components/add-multiple-matches/add-multiple-matches.component';
import { ArticleListComponent } from './components/article-list/article-list.component';
import { MenubarModule } from 'primeng/menubar';
import { LabelListComponent } from './components/label-list/label-list.component';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MessageModule } from 'primeng/message';
import { leavePageGuard } from './guards/leave-page.guard';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CardModule } from 'primeng/card';
import { AutoFocusModule } from 'primeng/autofocus';
import { FocusTrapModule } from 'primeng/focustrap';
import { DatePipe } from '@angular/common';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { MessageService } from 'primeng/api';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CacheInterceptor } from './interceptors/http.interceptor';
import { HeaderInterceptor } from './interceptors/header.interceptor';

const routes: Routes = [
  { path: '', redirectTo: '/links', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, title: 'Anmelden' },
  {
    path: 'challenge',
    component: SetPasswordComponent,
    title: 'Neues Passwort setzen',
  },
  { path: 'products', component: ProductsComponent, canActivate: [authGuard] },
  {
    path: 'links',
    component: LinksComponent,
    canActivate: [authGuard],
    title: 'Verknüpfungen',
  },
  {
    path: 'add',
    component: AddMultipleMatchesComponent,
    canActivate: [authGuard],
    title: 'Verknüpfungen hinzufügen',
    // canDeactivate: [leavePageGuard],
  },
  {
    path: 'articles',
    component: ArticleListComponent,
    canActivate: [authGuard],
    title: 'Artikel',
  },
  {
    path: 'labels',
    component: LabelListComponent,
    canActivate: [authGuard],
    title: 'Schilder',
  },
  { path: '**', redirectTo: '/links', pathMatch: 'full' }
];

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    ProductsComponent,
    LinksComponent,
    SetPasswordComponent,
    AddMultipleMatchesComponent,
    ArticleListComponent,
    LabelListComponent,
  ],
  imports: [
    RouterModule.forRoot(routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'de-DE',
    }),
    BrowserModule,
    FileUploadModule,
    HttpClientModule,
    CheckboxModule,
    InputTextModule,
    DividerModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordModule,
    BrowserAnimationsModule,
    TabMenuModule,
    TableModule,
    RippleModule,
    DropdownModule,
    MultiSelectModule,
    ChipsModule,
    SelectButtonModule,
    StepsModule,
    ChipModule,
    MenubarModule,
    ConfirmPopupModule,
    MessageModule,
    ConfirmDialogModule,
    CardModule,
    AutoFocusModule,
    FocusTrapModule,
    VirtualScrollerModule,
    LanguageSwitcherComponent,
    TopBarComponent,
  ],
  providers: [
    DatePipe,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
