<div class="main">
  @if (showMenu) {
    <div>
      <app-navbar></app-navbar>
    </div>
  }
  <div class="content">
    <p-messages></p-messages>
    <router-outlet></router-outlet>
  </div>
  @if(showMenu){
    <footer>
      <app-top-bar></app-top-bar>
    </footer>
  }
</div>
