import {
  Component,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {DataService} from '../../services/data.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {debounceTime, Subject} from 'rxjs';
import {MenuItem} from 'primeng/api/menuitem';
import {PriceLabel} from '../../models/PriceLabel';
import {CanDeactivateType} from '../../guards/leave-page.guard';
import {ILouisArticleDto} from '../../models/Product';
import {AuthService} from '../../services/auth.service';
import {TranslateService} from '@ngx-translate/core';

enum ScanState {
  Article,
  Label,
}

@Component({
  selector: 'app-add-multiple-matches',
  templateUrl: './add-multiple-matches.component.html',
  styleUrls: ['./add-multiple-matches.component.css'],
  providers: [ConfirmationService],
})
export class AddMultipleMatchesComponent implements OnInit, OnDestroy {
  barcodeInput: string = '';
  barcodeInputSubject: Subject<string> = new Subject();
  scanState: ScanState = ScanState.Article;

  private authService = inject(AuthService);

  matchingSteps: MenuItem[] = [];
  stepIndex: number = 0;
  separatorExp = /,| /;

  products: ILouisArticleDto[] = [];
  selectedProduct?: ILouisArticleDto;

  labelsIds: string[] = [];
  labelsToUpload: PriceLabel[] = [];

  scenarioOptions: any[] = [
    {label: 'Regal', value: 'Regal'},
    {label: 'Artikel', value: 'Artikel'},
  ];

  selectedScenario: string = 'Regal';

  constructor(
    private api: DataService,
    private messageService: MessageService,
    private confirmation: ConfirmationService,
    private translate: TranslateService
  ) {
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      if (this.labelsToUpload && this.labelsToUpload.length > 0) {
        e.preventDefault();
        e.returnValue =
          'Du befindest dich gerade in der Bearbeitung. Soll das Fenster trotzdem geschlossen werden? Änderung gehen dabei verloren.';
        return;
      }
      delete e['returnValue'];
    };

    this.barcodeInputSubject
      .pipe(debounceTime(100))
      .subscribe(barcode => this.handleBarcodeInput(barcode));

    this.translate
      .stream(['StepChooseArticle', 'StepScanLabels', 'StepChooseScenario'])
      .subscribe(res => {
        this.matchingSteps = [
          {label: res['StepChooseArticle']},
          {label: res['StepScanLabels']},
          {label: res['StepChooseScenario']},
        ];
      });
  }

  @HostListener('window:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (
      event.key !== 'Enter' &&
      event.key !== 'Alt' &&
      event.key !== 'Escape' &&
      event.key !== 'Backspace' &&
      !event.ctrlKey
    ) {
      this.barcodeInput += event.key;
    } else {
      this.barcodeInputSubject.next(this.barcodeInput);
      this.barcodeInput = ''; // Reset the input string
    }
  }

  private handleBarcodeInput(barcode: string) {
    if (
      this.stepIndex === 0 &&
      (this.scanState === ScanState.Article ||
        this.selectedProduct === undefined)
    ) {
      barcode = barcode.replace(/Shift/g, '');
      this.selectedProduct = this.products.find(
        p => p.id === barcode || p.barcode === barcode
      );

      if (this.selectedProduct) {
        this.stepIndex = 1;
        this.scanState = ScanState.Label;
      }
    }
  }

  ngOnInit() {
    this.api.getArticles().subscribe(data => {
      this.products = data;
    });
  }

  nextStep() {
    if (this.stepIndex < this.matchingSteps.length - 1) {
      this.stepIndex++;
    }
  }

  saveForm() {
    if (this.selectedProduct) {
      for (const labelId of this.labelsIds) {
        const newLabel: PriceLabel = {
          id: labelId,
          scenario: this.selectedScenario,
          productId: this.selectedProduct.id,
          storeId: this.authService.user?.storeId!,
        };

        this.addLabel(newLabel);
      }
    }

    this.clearForm();
    this.stepIndex = 0;
  }

  clearForm() {
    this.selectedProduct = undefined;
    this.labelsIds = [];
    this.selectedScenario = 'Regal';
  }

  cancel() {
    this.labelsToUpload = [];
  }

  addLabel(newLabel: PriceLabel) {
    this.labelsToUpload = this.labelsToUpload.filter(l => l.id !== newLabel.id);
    this.labelsToUpload.push(newLabel);
  }

  uploadLabels() {
    this.api.saveLabel(this.labelsToUpload).subscribe({
      next: resp => {
        this.translate
          .stream([
            'MessageSuccessfulLabelUploadSummary',
            'MessageSuccessfulLabelUploadDetail',
          ])
          .subscribe(res => {
            this.messageService.add({
              severity: 'success',
              summary: res['MessageSuccessfulLabelUploadSummary'],
              detail: res['MessageSuccessfulLabelUploadDetail'],
              life: 5000,
            });
          });
        this.labelsToUpload = [];
      },
      error: err => {
        this.translate
          .stream([
            'MessageLabelUploadErrorSummary',
            'MessageLabelUploadErrorDetail',
          ])
          .subscribe(res => {
            this.messageService.add({
              severity: 'error',
              summary: res['MessageLabelUploadErrorSummary'],
              detail: res['MessageLabelUploadErrorDetail'],
              life: 5000,
            });
          });

        console.error(err);
      },
    });
  }

  prevStep() {
    if (this.stepIndex > 0) {
      if (this.stepIndex === 1) {
        this.scanState = ScanState.Article;
      } else {
        this.scanState = ScanState.Label;
      }

      this.stepIndex--;
    }
  }

  get canSaveForm() {
    let returnValue: boolean = true;

    if (!this.selectedProduct) {
      returnValue = false;
    }

    if (!this.labelsIds || this.labelsIds.length === 0) {
      returnValue = false;
    }

    return returnValue;
  }

  get canSave() {
    return this.labelsToUpload.length > 0;
  }
  ngOnDestroy() {
    window.onbeforeunload = () => {};
  }

  canDeactivate(): CanDeactivateType {
    if (this.labelsToUpload && this.labelsToUpload.length > 0) {
      const deactivateSubject = new Subject<boolean>();

      this.translate
        .stream([
          'MessageAcceptLabel',
          'MessageRejectLabel',
          'MessageUnsavedChangesMessage',
          'MessageUnsavedChangesHeader',
        ])
        .subscribe((res: {[x: string]: any}) => {
          this.confirmation.confirm({
            acceptLabel: res['MessageAcceptLabel'],
            rejectLabel: res['MessageRejectLabel'],
            message: res['MessageUnsavedChangesMessage'],
            icon: 'pi pi-info-circle',
            header: res['MessageUnsavedChangesHeader'],
            defaultFocus: 'reject',
            accept: () => {
              deactivateSubject.next(true);
            },
            reject: () => {
              deactivateSubject.next(false);
            },
          });
        });

      return deactivateSubject;
    }
    return true;
  }
}
