import {Component, Input} from '@angular/core';
import {MenuItem} from 'primeng/api/menuitem';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css'],
})
export class SetPasswordComponent {
  @Input() session: string | undefined = undefined;
  @Input() username: string | undefined = undefined;
  steps: MenuItem[] = [];
  stepIndex = 0;
  mode: 'NEW' | undefined;
  pwdTitle = 'Neues Passwort setzen';
  password: string | undefined;

  ongoingRequest = false;

  passwordRegex =
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';

  constructor(
    private api: AuthService,
    private router: Router,
    private messageService: MessageService,
    private translate: TranslateService
  ) {}

  newPasswordForm = new FormGroup({
    newPassword: new FormControl('', {
      validators: [Validators.required, Validators.pattern(this.passwordRegex)],
    }),
  });

  forgotPasswordForm = new FormGroup({
    usermail: new FormControl('', {
      validators: [Validators.required, Validators.email],
    }),
  });

  restPasswordForm = new FormGroup({
    confirmationCode: new FormControl('', {validators: Validators.required}),
    newPassword: new FormControl('', {
      validators: [Validators.required, Validators.pattern(this.passwordRegex)],
    }),
  });

  setNewPassword() {
    this.ongoingRequest = true;
    this.api
      .setNewRequiredPassword(
        this.newPasswordForm.controls.newPassword.value ?? '' //Abfrage ob beide gleich
      )
      .then(() => {
        this.ongoingRequest = false;
        this.router.navigateByUrl('/');
      })
      .catch(e => {
        this.translate
          .stream('MessagePasswordError')
          .subscribe((res: string) => {
            this.messageService.add({
              severity: 'error',
              summary: res,
              detail: (e as Error).message, // auslagern
            });
          });
        this.newPasswordForm.reset();
        this.ongoingRequest = false;
      });
  }
}
