<p-dropdown
  [options]="languages"
  [(ngModel)]="selectedLang"
  optionLabel="name"
  (onChange)="changeLanguage($event)"
>
  @if(selectedLang.code){
  <ng-template pTemplate="selectedItem">
    <div class="flex align-items-center gap-2">
      <img src="assets/flags/{{ selectedLang.code }}.svg" class="w-1rem" />
      <div>{{ selectedLang.name }}</div>
    </div>
  </ng-template>
  }
  <ng-template let-language pTemplate="item">
    <div class="flex align-items-center gap-2">
      <img src="assets/flags/{{ language.code }}.svg" class="w-1rem" />
      <div>{{ language.name }}</div>
    </div>
  </ng-template>
</p-dropdown>
